import { tw } from '@/utils/tw';
import BasePaginationTheme from 'base/components/Pagination/theme';

const Icon = tw.theme({
  extend: BasePaginationTheme.Icon,
});

const Pagination = tw.theme({
  extend: BasePaginationTheme,
});

export default Object.assign(Pagination, { Icon });
